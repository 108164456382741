import _object_spread from "@swc/helpers/src/_object_spread.mjs";
import _object_spread_props from "@swc/helpers/src/_object_spread_props.mjs";
var organizeFooter = function(footer) {
    var ref, ref1;
    return {
        app: footer === null || footer === void 0 ? void 0 : footer.app,
        footerLinks: (footer === null || footer === void 0 ? void 0 : (ref = footer.footerLinks) === null || ref === void 0 ? void 0 : ref.map(function(e) {
            var ref, ref1;
            return _object_spread_props(_object_spread({}, e), {
                category: ((ref1 = e === null || e === void 0 ? void 0 : (ref = e.links) === null || ref === void 0 ? void 0 : ref.find(function(l) {
                    return !!l.category && !l.url;
                })) === null || ref1 === void 0 ? void 0 : ref1.category) || ""
            });
        })) || [],
        copyright: footer === null || footer === void 0 ? void 0 : footer.copyright,
        socials: _object_spread_props(_object_spread({}, footer === null || footer === void 0 ? void 0 : footer.socials), {
            elements: footer === null || footer === void 0 ? void 0 : (ref1 = footer.socials) === null || ref1 === void 0 ? void 0 : ref1.elements
        }),
        mobileLinks: footer === null || footer === void 0 ? void 0 : footer.mobileLinks,
        footerTopBanner: footer === null || footer === void 0 ? void 0 : footer.footerTopBanner
    };
};
export var useFooter = function(cmsJson) {
    if (!cmsJson) {
        return {
            app: null,
            footerLinks: null,
            copyright: null,
            socials: null,
            mobileLinks: null,
            footerTopBanner: null
        };
    }
    var footer = organizeFooter(cmsJson.footer);
    return {
        footer: footer
    };
};
