import _tagged_template_literal from "@swc/helpers/src/_tagged_template_literal.mjs";
function _templateObject() {
    var data = _tagged_template_literal([
        "\n  display: flex;\n  flex-direction: column;\n  margin: 0 23px 0 24px;\n\n  @media (min-width: ",
        ") {\n    margin: 0 69px 0 32px;\n  }\n\n  @media (min-width: ",
        ") {\n    margin: 0 80px;\n  }\n"
    ]);
    _templateObject = function _templateObject() {
        return data;
    };
    return data;
}
function _templateObject1() {
    var data = _tagged_template_literal([
        "\n  a {\n    color: ",
        " !important;\n    font-weight: 700;\n\n    :visited {\n      color: ",
        " !important;\n    }\n\n    :hover {\n      color: ",
        " !important;\n    }\n  }\n"
    ]);
    _templateObject1 = function _templateObject1() {
        return data;
    };
    return data;
}
function _templateObject2() {
    var data = _tagged_template_literal([
        "\n  .capitalize-first {\n    text-transform: lowercase;\n  }\n  .capitalize-first::first-letter {\n    text-transform: uppercase;\n  }\n  margin: 0 auto;\n  margin-top: 32px;\n  margin-bottom: 32px;\n\n  @media (min-width: ",
        ") {\n    margin-top: 48px;\n  }\n\n  .button-slide-container {\n    width: 279px;\n\n    button,\n    a {\n      padding: 10.5px 0;\n    }\n\n    @media (min-width: ",
        ") {\n      width: 216px;\n    }\n\n    @media (min-width: ",
        ") {\n      width: 324px;\n    }\n  }\n"
    ]);
    _templateObject2 = function _templateObject2() {
        return data;
    };
    return data;
}
function _templateObject3() {
    var data = _tagged_template_literal([
        "\n  div {\n    margin-bottom: 0;\n\n    p {\n      margin-block: 0;\n    }\n  }\n"
    ]);
    _templateObject3 = function _templateObject3() {
        return data;
    };
    return data;
}
function _templateObject4() {
    var data = _tagged_template_literal([
        "\n  font-family: ",
        ";\n  font-weight: 700;\n  color: ",
        ";\n\n  img {\n    width: 18px;\n  }\n"
    ]);
    _templateObject4 = function _templateObject4() {
        return data;
    };
    return data;
}
import { breakpoints, colors, fonts } from "@vfit/shared/themes";
import styled from "styled-components";
export var FaqContainer = styled.div.withConfig({
    componentId: "sc-fc014dc2-0"
})(_templateObject(), breakpoints.tablet, breakpoints.desktop);
export var FaqAccordionWrapper = styled.div.withConfig({
    componentId: "sc-fc014dc2-1"
})(_templateObject1(), colors.$262626, colors.$262626, colors.$e60000);
export var FaqShowMore = styled.div.withConfig({
    componentId: "sc-fc014dc2-2"
})(_templateObject2(), breakpoints.bigDesktop, breakpoints.tablet, breakpoints.bigDesktop);
export var Text = styled.div.withConfig({
    componentId: "sc-fc014dc2-3"
})(_templateObject3());
export var DisclaimerContainer = styled.div.withConfig({
    componentId: "sc-fc014dc2-4"
})(_templateObject4(), fonts.regular, colors.$7e7e7e);
