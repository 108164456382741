import _tagged_template_literal from "@swc/helpers/src/_tagged_template_literal.mjs";
function _templateObject() {
    var data = _tagged_template_literal([
        "\n  margin-top: 63px;\n\n  @media (min-width: ",
        ") {\n    margin-top: 0;\n  }\n"
    ]);
    _templateObject = function _templateObject() {
        return data;
    };
    return data;
}
function _templateObject1() {
    var data = _tagged_template_literal([
        "\n  display: flex;\n  flex: 1;\n  flex-direction: column;\n  background: linear-gradient(45deg, ",
        " 0%, ",
        " 100%);\n  border-radius: 30px 30px 0 0;\n  justify-content: space-between;\n  align-items: center;\n  padding: 36px 24px;\n\n  p {\n    font-family: ",
        ";\n    ",
        "\n    font-weight: 400;\n    color: ",
        ";\n    margin: 0;\n    margin-bottom: 24px;\n    text-align: center;\n  }\n\n  @media (min-width: ",
        ") {\n    p {\n      ",
        "\n      width: 327px;\n    }\n  }\n\n  @media (min-width: ",
        ") {\n    flex-direction: row;\n    padding: 60px;\n\n    p {\n      text-align: inherit;\n      margin-bottom: 0;\n      font-family: ",
        ";\n      ",
        "\n      width: auto;\n    }\n  }\n"
    ]);
    _templateObject1 = function _templateObject1() {
        return data;
    };
    return data;
}
function _templateObject2() {
    var data = _tagged_template_literal([
        "\n  display: flex;\n  flex-direction: column;\n  background-color: ",
        ";\n  padding: 36px 24px;\n  flex: 1;\n\n  @media (min-width: ",
        ") {\n    padding: 40px 60px;\n  }\n"
    ]);
    _templateObject2 = function _templateObject2() {
        return data;
    };
    return data;
}
function _templateObject3() {
    var data = _tagged_template_literal([
        "\n  border-bottom: 1px solid ",
        ";\n  display: flex;\n  flex-direction: row;\n  justify-content: space-between;\n  align-items: center;\n  padding-bottom: 26px;\n\n  p {\n    margin: 0;\n    margin-bottom: 18px;\n    font-family: ",
        ";\n    color: white;\n    ",
        "\n    font-weight: 700;\n  }\n"
    ]);
    _templateObject3 = function _templateObject3() {
        return data;
    };
    return data;
}
function _templateObject4() {
    var data = _tagged_template_literal([
        "\n  display: flex;\n  flex-direction: column;\n  min-height: 72px;\n  padding-left: 24px;\n  padding-right: 24px;\n  padding-top: 16px;\n  padding-bottom: 16px;\n\n  p {\n    margin: 0;\n    font-family: ",
        ";\n    color: ",
        ";\n    ",
        "\n    font-weight: 400;\n  }\n  a {\n    color: ",
        ";\n    ",
        "\n    font-weight: 400;\n    font-family: ",
        ";\n    text-decoration: none;\n  }\n\n  @media (min-width: ",
        ") {\n    p {\n      width: 50px;\n    }\n    flex-direction: row;\n    justify-content: space-between;\n    align-items: center;\n  }\n\n  @media (min-width: ",
        ") {\n    padding-left: 60px;\n    padding-right: 60px;\n    p {\n      width: 50px;\n    }\n    flex-direction: row;\n    justify-content: space-between;\n    align-items: center;\n  }\n"
    ]);
    _templateObject4 = function _templateObject4() {
        return data;
    };
    return data;
}
function _templateObject5() {
    var data = _tagged_template_literal([
        "\n  display: flex;\n  flex-direction: column;\n  flex-grow: 1;\n  flex-basis: 0;\n  padding-top: 0;\n  margin-bottom: 16px;\n  :first-child {\n    margin-bottom: 32px;\n  }\n  a {\n    font-weight: 700;\n  }\n  @media (min-width: ",
        ") {\n    :first-child {\n      margin-bottom: 40px;\n    }\n    min-height: 24px;\n  }\n  @media (min-width: ",
        ") {\n    :first-child {\n      margin-bottom: 0;\n    }\n    min-height: inherit;\n    a {\n      font-weight: 400;\n    }\n    flex-direction: row;\n    align-items: center;\n    margin-bottom: 0;\n  }\n  :last-child {\n    margin-bottom: 0;\n  }\n"
    ]);
    _templateObject5 = function _templateObject5() {
        return data;
    };
    return data;
}
function _templateObject6() {
    var data = _tagged_template_literal([
        "\n  display: flex;\n  flex-direction: row;\n  flex-grow: 1;\n  flex-basis: 0;\n  padding-top: 24px;\n"
    ]);
    _templateObject6 = function _templateObject6() {
        return data;
    };
    return data;
}
function _templateObject7() {
    var data = _tagged_template_literal([
        "\n  flex-grow: 1;\n  width: 25%;\n\n  p {\n    margin: 0;\n    margin-bottom: 18px;\n    font-family: ",
        ";\n    color: white;\n    ",
        "\n    font-weight: 700;\n  }\n\n  div {\n    display: flex;\n    flex-direction: column;\n\n    a {\n      color: white;\n      ",
        "\n      font-weight: 400;\n      font-family: ",
        ";\n      text-decoration: none;\n      margin-bottom: 5px;\n    }\n  }\n"
    ]);
    _templateObject7 = function _templateObject7() {
        return data;
    };
    return data;
}
function _templateObject8() {
    var data = _tagged_template_literal([
        "\n  margin-top: 24px;\n  text-align: center;\n\n  span {\n    color: ",
        ";\n    font-family: ",
        ";\n    font-weight: 400;\n    ",
        "\n  }\n"
    ]);
    _templateObject8 = function _templateObject8() {
        return data;
    };
    return data;
}
function _templateObject9() {
    var data = _tagged_template_literal([
        "\n  display: flex;\n  flex-flow: row wrap;\n  justify-content: center;\n  flex: 1 1 65px;\n  align-items: center;\n"
    ]);
    _templateObject9 = function _templateObject9() {
        return data;
    };
    return data;
}
function _templateObject10() {
    var data = _tagged_template_literal([
        "\n  margin-top: 20px;\n  padding-left: 10px;\n  padding-right: 10px;\n  row-gap: 10px;\n  cursor: pointer;\n  border-left: ",
        ";\n\n  span {\n    color: ",
        ";\n    font-family: ",
        ";\n    font-style: normal;\n    ",
        "\n    font-weight: 400;\n  }\n"
    ]);
    _templateObject10 = function _templateObject10() {
        return data;
    };
    return data;
}
function _templateObject11() {
    var data = _tagged_template_literal([
        "\n  background: none;\n  color: inherit;\n  border: none;\n  padding: 0;\n  font: inherit;\n  cursor: pointer;\n  outline: inherit;\n  margin-right: ",
        ";\n\n  img.apps {\n    width: 146px;\n  }\n\n  img {\n    cursor: pointer;\n  }\n\n  @media (min-width: ",
        ") {\n    img.apps {\n      width: ",
        ";\n    }\n  }\n\n  // TOFix\n\n  @media only screen and (max-width: 375px) and (min-width: 290px) {\n    img.apps {\n      width: 119px;\n    }\n  }\n\n  @media only screen and (max-width: 289px) and (min-width: 0) {\n    img.apps {\n      width: 80px;\n    }\n  }\n"
    ]);
    _templateObject11 = function _templateObject11() {
        return data;
    };
    return data;
}
function _templateObject12() {
    var data = _tagged_template_literal([
        "\n  @media (min-width: ",
        ") {\n    height: ",
        ";\n  }\n"
    ]);
    _templateObject12 = function _templateObject12() {
        return data;
    };
    return data;
}
function _templateObject13() {
    var data = _tagged_template_literal([
        "\n  display: flex;\n  flex-direction: row;\n"
    ]);
    _templateObject13 = function _templateObject13() {
        return data;
    };
    return data;
}
function _templateObject14() {
    var data = _tagged_template_literal([
        "\n  margin-right: 23px;\n  height: 40px;\n  img {\n    object-fit: contain !important;\n    width: auto !important;\n  }\n"
    ]);
    _templateObject14 = function _templateObject14() {
        return data;
    };
    return data;
}
import styled from "styled-components";
import { breakpoints, colors, fonts, pxToCssFont, pxToCssFontSize, pxToRem } from "@vfit/shared/themes";
export var FooterContainer = styled.footer.withConfig({
    componentId: "sc-68dc8a19-0"
})(_templateObject(), breakpoints.desktop);
export var FooterTopContainer = styled.div.withConfig({
    componentId: "sc-68dc8a19-1"
})(_templateObject1(), colors.$820000, colors.$e60000, fonts.regular, pxToCssFont(20, 28), colors.$ffffff, breakpoints.tablet, pxToCssFont(20, 28), breakpoints.desktop, fonts.light, pxToCssFont(30, 38));
export var FooterBottomContainer = styled.div.withConfig({
    componentId: "sc-68dc8a19-2"
})(_templateObject2(), colors.$0d0d0d, breakpoints.desktop);
export var FooterBottomContent = styled.div.withConfig({
    componentId: "sc-68dc8a19-3"
})(_templateObject3(), colors.$bebebe, fonts.regular, pxToCssFontSize(20));
export var TopFooterBannerBottomContent = styled.div.withConfig({
    componentId: "sc-68dc8a19-4"
})(_templateObject4(), fonts.instrumentSansRegular, function(param) {
    var color = param.color;
    return color || "white";
}, pxToCssFontSize(16), function(param) {
    var color = param.color;
    return color || "white";
}, pxToCssFontSize(18), fonts.light, breakpoints.tablet, breakpoints.desktop);
export var FooterTopBannerLinks = styled.div.withConfig({
    componentId: "sc-68dc8a19-5"
})(_templateObject5(), breakpoints.tablet, breakpoints.desktop);
export var FooterLinks = styled.div.withConfig({
    componentId: "sc-68dc8a19-6"
})(_templateObject6());
export var FooterLinksCol = styled.div.withConfig({
    componentId: "sc-68dc8a19-7"
})(_templateObject7(), fonts.regular, pxToCssFontSize(20), pxToCssFontSize(18), fonts.regular);
export var FooterCopyright = styled.div.withConfig({
    componentId: "sc-68dc8a19-8"
})(_templateObject8(), colors.$bebebe, fonts.regular, pxToCssFontSize(18));
export var FooterMobileLinks = styled.div.withConfig({
    componentId: "sc-68dc8a19-9"
})(_templateObject9());
export var FooterMobileLink = styled.div.withConfig({
    componentId: "sc-68dc8a19-10"
})(_templateObject10(), function(param) {
    var borderLeft = param.borderLeft;
    return borderLeft ? "1px solid #BEBEBE" : "none";
}, colors.$bebebe, fonts.regular, pxToCssFont(16, 22));
export var FooterButton = styled.button.withConfig({
    componentId: "sc-68dc8a19-11"
})(_templateObject11(), function(param) {
    var marginRight = param.marginRight;
    return marginRight ? "".concat(marginRight, "px") : 0;
}, breakpoints.desktop, function(param) {
    var imageWidth = param.imageWidth;
    return pxToRem(imageWidth || 146);
});
export var FooterAppWrapper = styled.div.withConfig({
    componentId: "sc-68dc8a19-12"
})(_templateObject12(), breakpoints.desktop, function(param) {
    var height = param.height;
    return height ? pxToRem(height) : "inherit";
});
export var FooterFirstPart = styled.div.withConfig({
    componentId: "sc-68dc8a19-13"
})(_templateObject13());
export var LogoContainer = styled.div.withConfig({
    componentId: "sc-68dc8a19-14"
})(_templateObject14());
